/* Navbar Container */
.navbar {
  background-color: #222; /* Darker background for a sleek look */
  padding: 10px 20px; /* Adjusted padding for better spacing */
  position: sticky; /* Sticky navbar that stays on top */
  top: 0;
  z-index: 1000; /* Ensures navbar stays above all content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
}

/* Navbar Links Container */
.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navbar Items */
.navbar li {
  margin: 0 10px;
}

/* Navbar Links */
.navbar li a {
  color: white; /* White text for contrast */
  font-size: 1.1em; /* Slightly larger font size for readability */
  font-weight: 500; /* Semi-bold text for a modern look */
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

/* Hover Effect for Links */
.navbar li a:hover {
  background-color: #444; /* Darker background on hover */
  color: #61dafb; /* Accent color for hover effect */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow on hover */
}

/* Active Link */
.navbar li a.active {
  background-color: #61dafb; /* Highlight active link */
  color: #222; /* Dark text for contrast */
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start;
    padding: 10px;
  }

  .navbar li {
    margin: 5px 0;
  }

  .navbar li a {
    width: 100%; /* Full-width links for easier tapping */
    text-align: left;
  }
}

/* Navbar Styles */
.navbar {
  background-color: #333;
  overflow: hidden;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0; /* Remove margin */
}

/* Reset list and link styles */
.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.navbar li {
  float: left;
}

.navbar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar li a:hover {
  background-color: #111;
}

.navbar a {
  color: white;
  text-decoration: none;
}
