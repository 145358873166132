/* Hero Section */
.hero {
  background-color: #282c34; /* Neutral background */
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0; /* Remove padding */
  margin-top: 0; /* Ensure no gap above */
}

.hero-content {
  max-width: 600px; /* Restricts the width of the content */
}

.hero h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.hero p {
  font-size: 1.5em;
  margin-bottom: 1.5em;
}

.hero-button {
  background-color: #61dafb;
  color: #282c34;
  padding: 0.8em 1.5em;
  text-decoration: none;
  border-radius: 8px; /* Slightly rounded for a modern feel */
  font-size: 1.2em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-button:hover {
  background-color: #4db8ff; /* Slightly brighter on hover */
  transform: scale(1.05); /* Subtle zoom effect */
}

/* Optional Scroll-Down Icon */
.scroll-down {
  margin-top: 20px;
}

.scroll-down a img {
  width: 30px;
  height: 30px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
