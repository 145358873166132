.App {
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 0;
  margin: 35px 0 0 0;
}

section {
  padding: 20px 10px;
}

.navbar {
  background-color: #333;
  padding: 2px;
  height: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  align-items: center;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadeIn 2s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scroll-down {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.scroll-down.visible {
  opacity: 1;
}
