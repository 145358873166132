
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Footer.css */
.footer {
  background-color: #111; /* Darker background */
  color: #fff;
  text-align: center;
  padding: 20px; /* Increased padding */
  font-size: 0.9em; /* Smaller text */
}
