
.About {
  padding: 20px;
}

/* Example for About.css */
.About {
  padding: 40px; /* Increased padding */
  margin: 20px 0; /* Add margin for spacing */
}
