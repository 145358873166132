/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

/* Section Styling */
.Projects {
  padding: 50px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e3e3e3;
}

.Projects h3 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Project List Styling */
.Projects ul {
  list-style: none;
  padding: 0;
}

.Projects li {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Projects li:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.Projects li h4 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
}

.Projects li p {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
  line-height: 1.6;
}

.Projects a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.Projects a:hover {
  background-color: #0056b3;
}
