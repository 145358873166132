/* General Styling */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
  
  /* Section Styling */
  #certifications {
    padding: 50px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e3e3e3;
  }
  
  #certifications h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  /* Certification Card Styling */
  .certification {
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f4f4f4;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .certification:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .certification h3 {
    font-size: 1.8rem;
    color: #222;
    margin-bottom: 10px;
  }
  
  .certification p {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
    line-height: 1.6;
  }
  
  .certification a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .certification a:hover {
    background-color: #0056b3;
  }
  